import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import WalletConnect from '@walletconnect/client'
import type { Backend, GitHubConnection, Self, UserPreferences } from '~api'
import { AUTH_LOCAL_STORAGE_TOKEN } from '~data/auth/auth.consts'
import type { RootState } from '~data/store'
import { fetchSubscription } from '~data/subscription/subscription.actions'
import { setCookie } from '~utils/cookie'
import { BRIDGE_URL } from '~utils/wallets'
import { fetchBalance } from './auth.balance'
import { checkGitHubUserOutdated, dropHubspot, userToStorageToken } from './auth.helpers'
import { sendUpdateSettings } from './auth.preferences'
import type { UserState } from './auth.types'
import { userFromStorage } from './auth.utils'

let balanceCheckTimer: ReturnType<typeof setInterval> | null = null

export const signinAction = createAction<UserState>('auth/signin')
export const signoutAction = createAction<void>('auth/signout')
export const connectUser = createAction<GitHubConnection>('auth/connect')
export const disconnectUser = createAction<void>('auth/disconnect')

export const signinUser = createAsyncThunk<void, { storage: Self; backend: Backend }, { state: RootState }>(
  'auth/signin-user',
  ({ storage, backend }, { dispatch }) => {
    const newUser = userFromStorage(storage)

    window.localStorage[AUTH_LOCAL_STORAGE_TOKEN] = userToStorageToken(newUser)

    if (newUser.isAuthorized) {
      dispatch(signinAction(newUser))
      dispatch(fetchSubscription({ backend }))

      if (typeof window !== 'undefined') {
        if (backend.isGitHubCustomGlobalTokenSupported()) {
          checkGitHubUserOutdated(newUser)
        }
        balanceCheckTimer = setInterval(() => {
          dispatch(fetchBalance({ backend }))
        }, 5000)
      }
    } else {
      dispatch(signoutAction())
    }
  },
)

export const signoutUser = createAsyncThunk<void, void, { state: RootState }>('auth/signout-user', (_args, { dispatch }) => {
  const walletConnect = new WalletConnect({ bridge: BRIDGE_URL })

  window.localStorage[AUTH_LOCAL_STORAGE_TOKEN] = userToStorageToken(undefined)

  if (walletConnect.connected) walletConnect.killSession()
  dispatch(signoutAction())
  setCookie('commentoCommenterToken', 'anonymous', { path: '/' })
  dropHubspot()
  if (balanceCheckTimer) {
    clearInterval(balanceCheckTimer)
  }
  balanceCheckTimer = null

  window.location.href = '/'
})

// this is used to load user from backend. we don't do any token-related actions here
export const replaceUser = createAsyncThunk<void, { user: UserState; backend: Backend }, { state: RootState }>(
  'auth/replace-user',
  ({ user, backend }, { dispatch }) => {
    window.localStorage[AUTH_LOCAL_STORAGE_TOKEN] = userToStorageToken(user)

    dispatch(signinAction(user))

    if (user.isAuthorized) {
      dispatch(fetchSubscription({ backend }))

      if (typeof window !== 'undefined') {
        if (backend.isGitHubCustomGlobalTokenSupported()) {
          checkGitHubUserOutdated(user)
        }
        dispatch(fetchBalance({ backend }))
        if (balanceCheckTimer) {
          clearInterval(balanceCheckTimer)
        }
        balanceCheckTimer = setInterval(() => {
          dispatch(fetchBalance({ backend }))
        }, 5000)
      }
    }
  },
)

export const updatePreferences = createAsyncThunk<
  Promise<void>,
  { newPreferences: Partial<UserPreferences>; backend: Backend },
  { state: RootState }
>('auth/update-preferences', async ({ newPreferences, backend }, { dispatch, getState }) => {
  const {
    auth: { user },
  } = getState()
  const newUserObject = { ...user, preferences: { ...user.preferences, ...newPreferences } as UserPreferences }

  dispatch(signinAction(newUserObject))
  await dispatch(sendUpdateSettings({ newSettings: newPreferences as UserPreferences, backend }))
})
