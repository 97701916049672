import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '~data/store'
import type { ListingName } from './listing.types'

export const metaSelector = (state: RootState) => state.ui.listing

export const uiListingSelector = (listingName?: ListingName) => createSelector(metaSelector, state => (listingName ? state[listingName] : undefined))
export const uiListingPageSizeSelector = (listing: ListingName) => createSelector(metaSelector, state => state[listing].pageSize)
export const uiListingSortBySelector = (listing: ListingName) => createSelector(metaSelector, state => state[listing].sortBy)
export const uiListingSortDirectionSelector = (listing: ListingName) => createSelector(metaSelector, state => state[listing].sortDirection)
export const uiListingViewTypeSelector = (listing: ListingName) => createSelector(metaSelector, state => state[listing].viewType)
export const uiListingModeSelector = (listing: ListingName) => createSelector(metaSelector, state => state[listing].mode)
