import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './listing.consts'
import type { ListingName, SliceState, ViewType } from './listing.types'

const slice = createSlice({
  name: 'ui/listing',
  initialState,
  reducers: {
    replace: (_state, action: PayloadAction<SliceState>) => ({ ...initialState, ...action.payload }),
    setPageSize: (state, action: PayloadAction<{ listing: ListingName; pageSize: number }>) => {
      const { listing, pageSize } = action.payload

      state[listing].pageSize = pageSize
    },
    setSortBy: (state, action: PayloadAction<{ listing: ListingName; sortBy: string }>) => {
      const { listing, sortBy } = action.payload

      state[listing].sortBy = sortBy
    },
    setSortDirection: (state, action: PayloadAction<{ listing: ListingName; sortDirection: string }>) => {
      const { listing, sortDirection } = action.payload

      state[listing].sortDirection = sortDirection
    },
    setViewType: (state, action: PayloadAction<{ listing: ListingName; viewType: ViewType }>) => {
      const { listing, viewType } = action.payload

      state[listing].viewType = viewType
    },
    setMode: (state, action: PayloadAction<{ listing: ListingName; mode: string }>) => {
      const { listing, mode } = action.payload

      state[listing].mode = mode
    },
  },
})

export const { replace: replaceListingSettings, setPageSize, setMode, setSortDirection, setSortBy, setViewType } = slice.actions

const listingReducer = slice.reducer

export default listingReducer
