import {
  SidebarCodeRightTab,
  SidebarCodeTab,
  SidebarExplorerPanel,
  SidebarLicensePanel,
  SidebarName,
  SidebarPropertiesPanel,
  SidebarSliceState,
  SidebarTeamPropertiesPanel,
  SidebarTeamTab,
} from './sidebar.types'

export const sidebarVersion = 30

export const sidebarInitialState: SidebarSliceState = {
  [SidebarName.primary]: {
    isOpened: false,
    width: 224,
    tab: 'filter',
    panels: [],
  },
  [SidebarName.code]: {
    isOpened: true,
    width: 280,
    tab: SidebarCodeTab.explorer,
    panels: [SidebarExplorerPanel.files, SidebarExplorerPanel.connections, SidebarExplorerPanel.datasets],
    panelsSize: {},
  },
  [SidebarName.codeRight]: {
    isOpened: false,
    width: 336,
    tab: SidebarCodeRightTab.properties,
    panels: [
      SidebarPropertiesPanel.customize,
      SidebarPropertiesPanel.publish,
      SidebarPropertiesPanel.createdBy,
      SidebarPropertiesPanel.ownedBy,
      SidebarPropertiesPanel.details,
      SidebarLicensePanel.licenseSelector,
    ],
  },
  [SidebarName.team]: {
    isOpened: false,
    width: 336,
    tab: SidebarTeamTab.properties,
    panels: [SidebarTeamPropertiesPanel.customize, SidebarTeamPropertiesPanel.discovery, SidebarTeamPropertiesPanel.delete],
  },
}
