import { repairPalette, Theme, ThemeMode, themes } from '@unbounded/unbounded-components'
import { darken, lighten } from 'polished'
import { CURRENT_PLATFORM } from '~routes/platform'
import type {
  monacoThemeColorsType,
  monacoThemeRuleType,
  themeDataProps,
  themeProps,
  VSCodeThemeProps,
  VSCodeThemeSettingsProps,
} from './EditorTheme.types'
import vsDarkTheme from './themes/Dark (Visual Studio).json'
import vsLightTheme from './themes/Light (Visual Studio).json'

function transformHex(hexcolor: string | undefined | null): string {
  let color = hexcolor || '#ffffff00'

  if (!hexcolor || hexcolor.length < 4) {
    color = '#ffffff00'
  } else if (hexcolor.length === 4) {
    color = `#${Array.from(hexcolor.slice(1))
      .map(hex => hex + hex)
      .join('')}`
  }

  return color
}

// https://github.com/Nishkalkashyap/monaco-vscode-textmate-theme-converter
export function convertTheme(theme: VSCodeThemeProps): themeDataProps {
  const monacoThemeRule: monacoThemeRuleType = []
  const monacoThemeColors: monacoThemeColorsType = {}
  const returnTheme: themeDataProps = {
    inherit: true,
    name: theme.name,
    base: theme.type === 'dark' ? 'vs-dark' : 'vs',
    colors: monacoThemeColors,
    rules: monacoThemeRule,
    encodedTokensColors: [],
  }

  Object.keys(theme.colors).forEach((colorId: string) => {
    monacoThemeColors[colorId] = transformHex(theme.colors[colorId])
  })

  theme.tokenColors.forEach(color => {
    let colorScope = color.scope

    function transformSettings(colorSettings: VSCodeThemeSettingsProps) {
      const settings = { ...colorSettings }

      Object.keys(settings).forEach(key => {
        if (key === 'foreground' || key === 'background') {
          settings[key] = transformHex(settings[key])
        }
      })

      return settings
    }

    function evalAsArray() {
      if (colorScope) {
        ;(colorScope as string[]).forEach(scope => {
          const settings = transformSettings(color.settings)

          monacoThemeRule.push({ ...settings, token: scope })
        })
      }
    }

    if (typeof colorScope === 'string') {
      const split = colorScope.split(',')

      if (split.length > 1) {
        colorScope = split
        evalAsArray()
        return
      }

      const settings = transformSettings(color.settings)

      monacoThemeRule.push({ ...settings, token: colorScope })
      return
    }

    evalAsArray()
  })

  return returnTheme
}

const VS_DARK_EDITOR_THEME_DATA = vsDarkTheme as themeDataProps
const VS_LIGHT_EDITOR_THEME_DATA = vsLightTheme as themeDataProps

export function createNewTheme(themeData: themeDataProps): Theme {
  const isDark = themeData.base === 'vs-dark'
  const parentTheme = themes[isDark ? CURRENT_PLATFORM.themes.dark : CURRENT_PLATFORM.themes.light]
  const vsBaseTheme = isDark ? VS_DARK_EDITOR_THEME_DATA : VS_LIGHT_EDITOR_THEME_DATA
  const colors = { ...themeData.colors, ...vsBaseTheme.colors }

  const foregroundColor = colors['editor.foreground']
  const backgroundColor = colors['editor.background']
  const primaryColor = colors['activityBarBadge.background'] || colors['button.background']

  const palette = repairPalette({
    ...parentTheme.palette,
    base: {
      shade_0: backgroundColor,
      shade_10: colors['sideBar.background'] || (isDark ? lighten(0.08, backgroundColor) : darken(0.08, backgroundColor)),
      shade_100: isDark ? lighten(0.25, foregroundColor) : lighten(0.1, foregroundColor),
    },
    primary: { color: primaryColor },
  })

  return {
    ...parentTheme,
    palette,
  }
}

export function getDefaultThemes(): Record<'default' | 'inverse', themeProps> {
  const defaultMode = CURRENT_PLATFORM.themeDefaultMode
  const inverseMode = defaultMode === ThemeMode.light ? ThemeMode.dark : ThemeMode.light

  return {
    default: {
      id: CURRENT_PLATFORM.themes[defaultMode],
      title: `${CURRENT_PLATFORM.name} (${defaultMode})`,
      mode: defaultMode,
    },
    inverse: {
      id: CURRENT_PLATFORM.themes[inverseMode],
      title: `${CURRENT_PLATFORM.name} (${inverseMode})`,
      mode: inverseMode,
    },
  }
}
