import type { SliceState } from './last-files-position.types'

export const lastFilesPositionVersion = 2

export const initialState: SliceState = {
  entityMeta: {},
  meta: {
    isLoading: true,
  },
}

export const LAST_FILE_POSITION_SAVE_INTERVAL_MS = 3000

export const MAX_PROJECT_FILES_POSITIONS_LIFESPAN_MS = 1000 * 60 * 60 * 24 * 14 // 14 days
