import { BaseColor, themeColor } from '@unbounded/unbounded-components'
import styled from 'styled-components'

const Link = styled.a`
  color: ${themeColor(BaseColor.max)};
`

export default function createGitHubNotification(text) {
  text = text.replace('\n', '<br>')
  return (
    <div>
      <span dangerouslySetInnerHTML={{ __html: text }} />
      <br />
      Go to <Link href="/profile/integration">your profile</Link> to renew your token.
    </div>
  )
}
