import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Backend, UserPreferences } from '~api'
import type { RootState } from '~data/store'

/** User preferences */
export const sendUpdateSettings = createAsyncThunk<
  Promise<void>,
  { newSettings: UserPreferences; backend: Backend; signal?: AbortSignal },
  { state: RootState }
>('auth/preferences', async ({ backend, newSettings }, thunkAPI) => backend.withSignal(thunkAPI.signal).sendUpdateSettings(newSettings))
