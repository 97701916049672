import { connect, ConnectedProps } from 'react-redux'
import type { Backend, GitHubConnection } from '~api'
import type { AppDispatch, RootState } from '~data/store'
import { connectUser, disconnectUser, replaceUser, signinUser } from './auth.actions'
import { userSelector } from './auth.selectors'
import type { UserState } from './auth.types'

const mapStateToProps = (state: RootState, { user: ownUser }: { user?: UserState; [x: string]: any }) => ({
  user: ownUser || userSelector(state),
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signinUser: (storage: any, backend: Backend) => dispatch(signinUser({ storage, backend })),
  replaceUser: (user: UserState, backend: Backend) => dispatch(replaceUser({ user, backend })),
  connectUser: (storage: GitHubConnection) => dispatch(connectUser(storage)),
  disconnectUser: () => dispatch(disconnectUser()),
})

export const withUser = connect(mapStateToProps, mapDispatchToProps)

export type WithUserProps = ConnectedProps<typeof withUser>
