import type { Channel, Socket } from 'phoenix'
import type { Backend } from '~api'
import PhoenixSocket from '~api/mipasa/connections/phoenix-socket'
import waitUntil from '~utils/waitUntil'
import BaseConnection, { BaseConnectionListeners, ConnectionError } from '../../common/connection'

enum SocketStatus {
  pending = 'pending',
  established = 'established',
}

interface SocketRecord {
  socket?: Socket
  status: SocketStatus
  url: string
  channels: Array<string>
}

const CONNECT_TIMEOUT = 60000
const JOIN_TIMEOUT = 60000
const SEND_COMMAND_TIMEOUT = 10000
const GLOBAL_SOCKETS: Array<SocketRecord> = []

export interface PhoenixConnectionListeners<T> extends BaseConnectionListeners {
  init: (payload: T) => void
  init_error: (error: ConnectionError) => void
  connection_error: (error: ConnectionError) => void
}

export default class PhoenixConnection<T extends PhoenixConnectionListeners<IP>, IP> extends BaseConnection<T> {
  protected readonly url: string

  protected readonly channelId: string

  protected readonly channelOpts: Record<string, unknown>

  protected channel?: Channel

  protected socketRecord?: SocketRecord

  protected subscribedToEvents: Record<string, boolean> = {}

  protected readonly parentBackend: Backend

  private connect_params: { token?: string } = {}

  private disconnectIntended = false

  constructor(backend: Backend, url: string, channelId: string, channelOpts: Record<string, unknown> = {}) {
    super()
    this.parentBackend = backend
    this.url = url
    this.channelId = channelId
    this.channelOpts = channelOpts
  }

  // gross TypeScript hack.
  // it does not recognize "this" as WebSocketConnectionListener<BaseConnectionListeners> due to T being generic.
  // to fix later somehow (not sure how), but this is better than @ts-ignore
  private eventProducer(): PhoenixConnection<PhoenixConnectionListeners<IP>, IP> {
    return this
  }

  private async fetchUserToken() {
    const { token } = await this.parentBackend.fetchToken()
    return token
  }

  private updateConnectParams = async () => {
    const token = await this.fetchUserToken()

    const isOnline = !window.navigator || window.navigator.onLine
    const isConnected = this.socketRecord?.socket?.connectionState() === 'open' && isOnline

    // Don't reset the token when there is no connection
    if (!token && !isConnected) {
      return
    }

    // BUG: Setting a key to `undefined` in params actually sends string `undefined` in URL.
    if (token) {
      this.connect_params.token = token
    } else {
      delete this.connect_params.token
    }
  }

  // must be declared like this to be bound correctly to this class
  private fetchParams = () => this.connect_params

  private setupSocket() {
    return new PhoenixSocket(this.url, { params: this.fetchParams, timeout: CONNECT_TIMEOUT, updateConnectParams: this.updateConnectParams })
  }

  protected mapPayload(name: keyof T, payload: unknown) {
    return payload
  }

  private setupChannel(socket: Socket) {
    this.channel = socket.channel(this.channelId, () => this.channelOpts)

    // in the case of reconnection (or channel recreation) we should add all listeners back
    Object.getOwnPropertyNames(this.subscribedToEvents).forEach(name => {
      if (this.channel) {
        this.channel.on(name, payload => this.callListeners(name as keyof T & string, ...([this.mapPayload(name as keyof T, payload)] as any)))
      }
    })

    this.channel
      .join(JOIN_TIMEOUT)
      .receive('error', ({ reason }) => {
        this.eventProducer().callListeners('init_error', { message: `Failed to join Phoenix channel: ${reason}` })
      })
      .receive('timeout', () => {
        // to-do: handle timeout. currently, it's the same as error
        this.eventProducer().callListeners('init_error', { message: `Failed to join Phoenix channel: Timeout` })
      })
      .receive('ok', payload => {
        this.eventProducer().callListeners('open')
        this.eventProducer().callListeners('init', this.mapPayload('init', payload) as IP)
      })

    this.channel.onError((reason: string | Record<string, unknown>) => {
      this.eventProducer().callListeners('error', {
        message: `Phoenix channel failed: ${typeof reason === 'string' ? reason : JSON.stringify(reason)}`,
      })
    })
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    // Due to this function being async and not awaited upon & the possibility of disconnect being
    // called during its operation, we're playing with disconnectIntended flag to handle an external
    // concurrent disconnect() call by making the late disconnect happen & avoiding channel leaks.
    this.disconnectIntended = false

    const rec = await this.allocateSocket()

    this.socketRecord = rec
    await waitUntil(() => rec.status === SocketStatus.established)
    const { socket } = rec

    if (socket && !this.disconnectIntended) {
      this.setupChannel(socket)
    }

    if (this.channel && this.disconnectIntended) {
      this.disconnect()
    }
  }

  disconnect() {
    this.disconnectIntended = true

    if (this.channel) {
      this.channel.leave()
      this.channel = undefined
    }
    this.deallocateSocket()
  }

  protected async sendSynchronousCommand<R>(command: string, requestPayload?: any): Promise<R> {
    if (this.channel) {
      const pushed = this.channel.push(command, requestPayload, SEND_COMMAND_TIMEOUT)

      return new Promise((resolve, reject) => {
        pushed
          .receive('ok', (responsePayload: R) => {
            resolve(responsePayload)
          })
          .receive('error', ({ reason }) => {
            reject(new Error(`Synchronous command failed: ${reason}`))
          })
          .receive('timeout', () => {
            reject(new Error(`Synchronous command failed: Timeout`))
          })
      })
    }
    throw new Error('Not connected')
  }

  protected sendCommand(command: string, payload?: any) {
    if (this.channel) {
      this.channel.push(command, payload, SEND_COMMAND_TIMEOUT)
    } else {
      throw new Error('Not connected')
    }
  }

  addEventListener<K extends keyof T & string>(name: K, listener: T[K] & ((payload: any) => void)) {
    super.addEventListener(name, listener)

    if (!this.channel) {
      this.subscribedToEvents[name] = true
      return
    }

    if (!this.subscribedToEvents[name]) {
      this.subscribedToEvents[name] = true
      this.channel.on(name, payload => this.callListeners(name, ...([this.mapPayload(name, payload)] as any)))
    }
  }

  async allocateSocket() {
    const existing = GLOBAL_SOCKETS.find(x => x.url === this.url && x.channels.indexOf(this.channelId) === -1)

    if (existing) {
      existing.channels.push(this.channelId)
      return existing
    }

    const created: SocketRecord = {
      status: SocketStatus.pending,
      url: this.url,
      channels: [this.channelId],
    }

    GLOBAL_SOCKETS.push(created)

    const socket = this.setupSocket()

    created.socket = socket
    created.status = SocketStatus.established

    socket.onError(() => {
      this.eventProducer().callListeners('connection_error', { message: 'Phoenix socket error' })
    })
    socket.onClose(() => {
      for (let i = 0; i < GLOBAL_SOCKETS.length; i++) {
        if (GLOBAL_SOCKETS[i].socket === socket) {
          GLOBAL_SOCKETS.splice(i, 1)
          i--
        }
      }
    })
    socket.connect()

    return created
  }

  deallocateSocket() {
    if (!this.socketRecord) {
      return
    }

    const channelIndex = this.socketRecord.channels.indexOf(this.channelId)

    if (channelIndex !== -1) {
      this.socketRecord.channels.splice(channelIndex, 1)
    }

    if (!this.socketRecord.channels.length) {
      this.socketRecord.socket?.disconnect()

      const socketIndex = GLOBAL_SOCKETS.indexOf(this.socketRecord)

      if (socketIndex !== -1) {
        GLOBAL_SOCKETS.splice(socketIndex, 1)
      }
    }
  }
}
