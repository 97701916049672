import type {
  Backend,
  BackendProjectFilesConnection,
  BackendProjectFilesConnectionListeners,
  BackendProjectFilesConnectionUpdate,
  ProjectDirectoryMetadata,
} from '~api/common/backend'
import type { File } from '~data/file/file-type'
import { MiPasaFileInfo, mapDirectory, mapFile } from '../utils'
import PhoenixConnection, { PhoenixConnectionListeners } from './phoenix-connection'

interface Listeners extends BackendProjectFilesConnectionListeners, PhoenixConnectionListeners<BackendProjectFilesConnectionUpdate> {}

interface MiPasaProjectFile {
  type: 'file'
  name: string
  full_name: string
  project_file: MiPasaFileInfo
}

interface MiPasaProjectDirectory {
  type: 'directory'
  name: string
  full_name: string
  metadata: ProjectDirectoryMetadata
}

type MiPasaProjectEntry = MiPasaProjectFile | MiPasaProjectDirectory

function mapEntry(entry: MiPasaProjectEntry, projectId: string): File {
  if (entry.type === 'file') {
    return mapFile(entry.project_file, projectId)
  }

  return mapDirectory(entry.full_name, projectId, entry.metadata)
}

function mapEntries(entries: Array<MiPasaProjectEntry>, projectId: string): Array<File> {
  return entries.map(entry => mapEntry(entry, projectId))
}

interface MiPasaUpdate {
  root?: Array<MiPasaProjectEntry>
  directories?: Record<string, Array<MiPasaProjectEntry>>
}

function mapUpdate(payload: MiPasaUpdate, projectId: string): BackendProjectFilesConnectionUpdate {
  return {
    root: payload.root ? mapEntries(payload.root, projectId) : undefined,
    directories: Object.fromEntries(Object.entries(payload.directories || {}).map(([k, v]) => [k, mapEntries(v, projectId)])),
  }
}

export default class MiPasaProjectFilesConnection
  extends PhoenixConnection<Listeners, BackendProjectFilesConnectionUpdate>
  implements BackendProjectFilesConnection
{
  private readonly internalState: BackendProjectFilesConnectionUpdate = {}

  private readonly projectId: string

  constructor(backend: Backend, projectId: string, initialPaths: Array<string>) {
    super(backend, `/api/mipasa/socket`, `project_files:${projectId}`, { paths: [...initialPaths] })
    this.projectId = projectId

    this.addEventListener('init', payload => {
      this.handleUpdateState(payload)
    })

    this.addEventListener('files_updated', payload => {
      this.handleUpdateState(payload)
    })
  }

  private handleUpdateState(payload: BackendProjectFilesConnectionUpdate) {
    this.internalState.root = payload.root || this.internalState.root
    this.internalState.directories = { ...(this.internalState.directories || {}), ...(payload.directories || {}) }

    // if the server decided that we should add some path, it's probably correct :)
    // this happens when we move a directory, and the server automatically adds the new directory name for us.
    // the channel needs to still correctly reconnect back to it.
    const currentPaths = (this.channelOpts.paths as Array<string>) || []
    const pathsThatAreNotRequestedButPresent = Object.keys(this.internalState.directories).filter(x => !currentPaths.includes(x))
    if (pathsThatAreNotRequestedButPresent.length) {
      this.channelOpts.paths = [...currentPaths, pathsThatAreNotRequestedButPresent]
    }
  }

  protected override mapPayload(event: keyof Listeners, payload: unknown) {
    if (event === 'init' || event === 'files_updated') {
      return mapUpdate(payload as MiPasaUpdate, this.projectId)
    }
    return payload
  }

  async fetchDirectory(path: string): Promise<Array<File>> {
    // this is so that after reconnecting we are watching the same files again
    const currentPaths = (this.channelOpts.paths as Array<string>) || []
    if (!currentPaths.includes(path)) {
      currentPaths.push(path)
    }
    this.channelOpts.paths = currentPaths

    const result: Array<MiPasaProjectEntry> = await this.sendSynchronousCommand('open_directory', { path })
    const entries = mapEntries(result, this.projectId)

    if (!this.internalState.directories) {
      this.internalState.directories = {}
    }

    this.internalState.directories[path] = entries
    return entries
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    super.connect()
  }

  public get state() {
    return this.internalState
  }
}
