import { v4 as uuid } from 'uuid'
import type { User as BackendUser, Self, UserPreferences } from '~api'
import { DEFAULT_EDITOR_THEME } from '~components/code/Editor/EditorTheme/EditorTheme.consts'
import { THEME_PREFERENCES_VERSION } from '~consts'
import {
  initialState as lastFilesPositionPrefsDefault,
  lastFilesPositionVersion as lastFilesPositionPrefsVersion,
} from '~data/last-files-position/last-files-position.consts'
import { initialState as listingPrefsDefault, listingVersion as listingPrefsVersion } from '~data/listing/listing.consts'
import { sidebarInitialState as sidebarPrefsDefault, sidebarVersion as sidebarPrefsVersion } from '~data/sidebar/sidebar.consts'
import { DEFAULT_TRIAL_PREFERENCES, TRIAL_PREFERENCES_VERSION } from '~data/trial/trial.consts'
import { isMiPasa } from '~routes/platform'
import Base58 from '~utils/base58'
import type { UserGitHubState, UserSlackState, UserState, UserUnboundedState } from './auth.types'

export const createDefaultPreferences = () =>
  ({
    themeVersion: THEME_PREFERENCES_VERSION,
    theme: {
      theme: DEFAULT_EDITOR_THEME,
    },
    listingVersion: listingPrefsVersion,
    listing: listingPrefsDefault,
    sidebarVersion: sidebarPrefsVersion,
    sidebar: sidebarPrefsDefault,
    lastFilesPositionVersion: lastFilesPositionPrefsVersion,
    lastFilesPosition: lastFilesPositionPrefsDefault.entityMeta,
    trialPreferencesVersion: TRIAL_PREFERENCES_VERSION,
    trialPreferences: DEFAULT_TRIAL_PREFERENCES,
  }) as UserPreferences

export const migratePreferences = (prefs?: UserPreferences) => {
  const defaultPreferences = createDefaultPreferences()

  if (prefs?.sidebarVersion === sidebarPrefsVersion) {
    defaultPreferences.sidebar = prefs.sidebar
  }

  if (prefs?.listingVersion === listingPrefsVersion) {
    defaultPreferences.listing = prefs.listing
  }

  if (prefs?.themeVersion === THEME_PREFERENCES_VERSION) {
    defaultPreferences.theme = prefs.theme
  }

  if (prefs?.lastFilesPositionVersion === lastFilesPositionPrefsVersion) {
    defaultPreferences.lastFilesPosition = prefs.lastFilesPosition
  }

  if (prefs?.trialPreferencesVersion === TRIAL_PREFERENCES_VERSION) {
    defaultPreferences.trialPreferences = prefs.trialPreferences
  }

  // non-versioned stuff is just written unconditionally
  return {
    ...prefs,
    ...defaultPreferences,
  } as UserPreferences
}

export const createDefaultUser = (storage?: Self): UserState => ({
  sessionExpiresAt: undefined,
  sessionExpired: storage?.sessionExpired || false,
  isAdmin: false,
  isValidator: false,
  isUnconfirmed: false,
  isMiPasaAuthorized: false,
  isHackathonAdmin: false,
  isAuthorized: false,
  isMasked: false,
  isSuspended: false,
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  username: '',
  createdAt: null,
  roles: [],
  gitHub: undefined,
  unbounded: undefined,
  balance: undefined,
  aboutNotebookFileId: undefined,
  preferences: createDefaultPreferences(),
  features: [],
  referralCode: '',
  isIDMManaged: true,
  isTelegramManaged: false,
  trial: undefined,
  isGuest: false,
  guestId: undefined,
})

export const userFromStorage = (storage: Self): UserState => {
  if (!storage || !storage.session || !storage.user) {
    return createDefaultUser(storage)
  }

  // if session expired, don't login
  if (new Date().getTime() > storage.session.expiration) {
    return createDefaultUser(storage)
  }

  const checkGitHub = (rawUser: BackendUser): UserGitHubState | undefined => {
    if (rawUser.githubUsername && rawUser.githubOAuthToken) {
      return {
        username: rawUser.githubUsername,
        avatar: rawUser.githubAvatar || '',
      }
    }
    return undefined
  }

  const checkUnbounded = (rawUser: BackendUser): UserUnboundedState | undefined => {
    if (rawUser.unboundedUsername && rawUser.unboundedUserId) {
      return {
        username: rawUser.unboundedUsername,
        avatar: rawUser.unboundedAvatar || '',
      }
    }
    return undefined
  }

  const checkSlack = (rawUser: BackendUser): UserSlackState | undefined => {
    if (rawUser.slackTeamName) {
      return {
        teamName: rawUser.slackTeamName,
      }
    }
    return undefined
  }

  return {
    sessionExpiresAt: storage.session.expiration,
    sessionExpired: storage.sessionExpired,
    isAdmin: (storage.session.roles || []).indexOf('domain-admin') !== -1,
    isValidator: (storage.session.roles || []).indexOf('data-validator') !== -1,
    isUnconfirmed: storage.session.confirmed === undefined ? true : !storage.session.confirmed,
    isMiPasaAuthorized: (storage.session.roles || []).indexOf('data-provider') !== -1,
    isHackathonAdmin: (storage.session.roles || []).indexOf('hackathon-admin') !== -1,
    isAuthorized: !storage.user.isSuspended && !storage.session.isGuest,
    isMasked: !!storage.session.adminId,
    isSuspended: storage.user.isSuspended,
    id: storage.user.id,
    firstName: storage.user.firstName,
    lastName: storage.user.lastName,
    avatar: storage.user.avatar,
    avatarIcon: storage.user.avatarIcon,
    username: storage.user.credentials.name,
    email: storage.user.credentials.email,
    token: storage.token,
    commentoToken: storage.commentoToken,
    createdAt: null,
    roles: storage.user?.roles || [],
    gitHub: checkGitHub(storage.user),
    unbounded: checkUnbounded(storage.user),
    slack: checkSlack(storage.user),
    balance: undefined,
    aboutNotebookFileId: storage.user?.aboutNotebookFileId,
    preferences: migratePreferences(storage.user.preferences),
    features: storage.user?.features || [],
    referralCode: storage.user.referralCode,
    isIDMManaged: storage.user.isIDMManaged ?? true,
    isTelegramManaged: storage.user.isTelegramManaged ?? false,
    trial: storage.trial,
    isGuest: storage.session.isGuest,
    guestId: storage.session.guestId,
  }
}

function generateNewGuestId() {
  const newGuestId = uuid().replace('-', '')
  const fromHexString = (hexString: string) => new Uint8Array((hexString.match(/.{1,2}/g) || []).map(byte => parseInt(byte, 16)))

  return Base58.encode(fromHexString(newGuestId))
}

export function resetGuestId() {
  if (!window.localStorage) {
    return
  }

  window.localStorage.guestId = generateNewGuestId()
}

export function getGuestId() {
  if (!window.localStorage || !isMiPasa()) {
    return undefined
  }

  if (!window.localStorage.guestId) {
    window.localStorage.guestId = generateNewGuestId()
  }

  return window.localStorage.guestId
}
