export enum FileTypes {
  CSV = 'csv',
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
  JSON = 'json',
  IPYNB = 'ipynb',
  Any = '*',
}

export interface NativeFile extends File {
  filepath: string
}
