import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Backend, RatePlan, Subscription } from '~api'
import type { RootState } from '~data/store'

interface SubscriptionParams {
  backend: Backend
}

/** Fetch current subscription */
export const fetchSubscription = createAsyncThunk<Subscription | null, SubscriptionParams, { state: RootState }>('subscription/current', async args =>
  args.backend.fetchSubscription(),
)

/** Change subscription */
interface ChangeSubscriptionParams extends SubscriptionParams {
  id: string
}

export const changeSubscription = createAsyncThunk<Subscription | null, ChangeSubscriptionParams, { state: RootState }>(
  'subscription/change',
  async args => args.backend.changeSubscription(args.id),
)

/** Cancel subscription */
export const cancelSubscription = createAsyncThunk<Subscription | null, SubscriptionParams, { state: RootState }>('subscription/cancel', async args =>
  args.backend.cancelSubscription(),
)

/** @todo To be implemented someday
 *  List of available rate plans */
export const fetchListRatePlans = createAsyncThunk<RatePlan[], undefined, { state: RootState }>('subscription/plans/list', async args => [])
