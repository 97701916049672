export enum SidebarName {
  primary = 'primary',
  code = 'code',
  codeRight = 'codeRight',
  team = 'team',
}

export enum SidebarSide {
  left = 'left',
  right = 'right',
}

export enum SidebarCodeTab {
  explorer = 'explorer',
  collaborators = 'collaborators',
  analysis = 'analysis',
  runs = 'runs',
  pasas = 'pasas',
}

export enum SidebarCodeRightTab {
  properties = 'properties',
  details = 'details',
  inspector = 'inspector',
  versions = 'versions',
  notebookCellList = 'notebookCellList',
  canvasAddWidget = 'canvasAddWidget',
  canvasConfigureWidget = 'canvasConfigureWidget',
}

export enum SidebarEthIDETab {
  ethereumIDE = 'ethereumIDE',
}

export enum SidebarExplorerPanel {
  files = 'files',
  connections = 'connections',
  datasets = 'datasets',
}

export enum SidebarPropertiesPanel {
  customize = 'customize',
  publish = 'publish',
  createdBy = 'createdBy',
  ownedBy = 'ownedBy',
  details = 'details',
  delete = 'delete',
}

export enum SidebarLicensePanel {
  licenseSelector = 'licenseSelector',
}

export enum SidebarEthIDEPanel {
  solc = 'solc',
  transactions = 'transactions',
  walletConnect = 'walletConnect',
}

export enum SidebarTeamTab {
  properties = 'properties',
  joinRequests = 'joinRequests',
  activity = 'activity',
}

export enum SidebarTeamPropertiesPanel {
  customize = 'customize',
  discovery = 'discovery',
  delete = 'delete',
}

export type SidebarPanel =
  | SidebarExplorerPanel
  | SidebarPropertiesPanel
  | SidebarLicensePanel
  | SidebarEthIDEPanel
  | SidebarTeamTab
  | SidebarTeamPropertiesPanel

export type SidebarResizablePanel = SidebarExplorerPanel

export type SidebarPanelsSize = Record<string, string>

interface SidebarState<TabName extends string = string, PanelName extends string = SidebarPanel, isPanelsResizable = false> {
  isOpened: boolean
  width: number
  tab: TabName
  // Opened panels
  panels: Array<PanelName>
  // Panels % size if panels are resizable
  panelsSize?: isPanelsResizable extends false ? undefined : SidebarPanelsSize
}

export type SidebarSliceState = {
  [SidebarName.primary]: SidebarState
  [SidebarName.code]: SidebarState<SidebarCodeTab, SidebarExplorerPanel, true>
  [SidebarName.codeRight]: SidebarState<SidebarCodeRightTab, SidebarPropertiesPanel | SidebarLicensePanel>
  [SidebarName.team]: SidebarState<SidebarTeamTab, SidebarTeamPropertiesPanel>
}
