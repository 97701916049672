import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Backend } from '~api'
import { updatePreferences } from '~data/auth'
import type { AppThunk, RootState } from '~data/store'
import { listingVersion } from './listing.consts'
import { metaSelector } from './listing.selectors'
import { setMode, setPageSize, setSortBy, setSortDirection, setViewType } from './listing.slice'
import type { ListingName, ViewType } from './listing.types'

const saveSettings = createAsyncThunk<Promise<void>, { backend: Backend }, { state: RootState }>('ui/listing/save', async ({ backend }, thunkAPI) => {
  const listing = metaSelector(thunkAPI.getState())

  await thunkAPI.dispatch(updatePreferences({ newPreferences: { listing, listingVersion }, backend }))
})

export const setListingPageSize =
  (listing: ListingName, pageSize: number, backend: Backend): AppThunk =>
  async dispatch => {
    dispatch(setPageSize({ listing, pageSize }))
    dispatch(saveSettings({ backend }))
  }

export const setListingSortBy =
  (listing: ListingName, sortBy: string, backend: Backend): AppThunk =>
  async dispatch => {
    dispatch(setSortBy({ listing, sortBy }))
    dispatch(saveSettings({ backend }))
  }

export const setListingSortDirection =
  (listing: ListingName, sortDirection: string, backend: Backend): AppThunk =>
  async dispatch => {
    dispatch(setSortDirection({ listing, sortDirection }))
    dispatch(saveSettings({ backend }))
  }

export const setListingViewType =
  (listing: ListingName, viewType: ViewType, backend: Backend): AppThunk =>
  async dispatch => {
    dispatch(setViewType({ listing, viewType }))
    dispatch(saveSettings({ backend }))
  }

export const setListingMode =
  (listing: ListingName, mode: string, backend: Backend): AppThunk =>
  async dispatch => {
    dispatch(setMode({ listing, mode }))
    dispatch(saveSettings({ backend }))
  }
