import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '~data/store'

export const authSelector = (state: RootState) => state.auth
export const userSelector = createSelector(authSelector, auth => auth.user)
export const isAuthenticatedSelector = createSelector(userSelector, user => user.isAuthorized)
export const isSuspendedSelector = createSelector(userSelector, user => user.isSuspended)
export const sessionExpiredSelector = createSelector(userSelector, user => user.sessionExpired)

/** User is admin selector */
export const isAdminSelector = createSelector(userSelector, user => user.isAdmin)
