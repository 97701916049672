import type { NotebookCellMetadata } from '~components/code/NotebookEditor/NotebookEditor.types'
import { sleep } from '~utils/waitUntil'
import type {
  Backend,
  BackendNotebookConnection,
  BackendNotebookConnectionCellOutputOpts,
  BackendNotebookConnectionCellOutputPayload,
  BackendNotebookConnectionCellType,
  BackendNotebookConnectionCompletionsPayload,
  BackendNotebookConnectionDataframeColumnsPayload,
  BackendNotebookConnectionExcelSheetsPayload,
  BackendNotebookConnectionExecuteCodeSnippetPayload,
  BackendNotebookConnectionListeners,
  BackendNotebookConnectionNotebookPayload,
  BackendNotebookConnectionParameters,
  BackendNotebookConnectionVarsListPayload,
} from '../../common/backend'
import PhoenixConnection, { PhoenixConnectionListeners } from './phoenix-connection'

interface Listeners extends BackendNotebookConnectionListeners, PhoenixConnectionListeners<BackendNotebookConnectionNotebookPayload> {}

type NotebookConnectionIdentifier = { projectId: string; fileId: string } | { embedId: string }

function isEmbedIdentifier(id: NotebookConnectionIdentifier): id is { embedId: string } {
  return typeof (id as any).embedId === 'string'
}

export default class MiPasaNotebookConnection
  extends PhoenixConnection<Listeners, BackendNotebookConnectionNotebookPayload>
  implements BackendNotebookConnection
{
  constructor(backend: Backend, id: NotebookConnectionIdentifier, inputParams: BackendNotebookConnectionParameters) {
    const params: Record<string, string> = {
      view_only: inputParams.viewOnly ? '1' : '0',
      input_mutation: inputParams.inputMutation ? '1' : '0',
      with_last_ok_run: inputParams.withLastOkRun ? '1' : '0',
      debug_mode: inputParams.debugMode ? '1' : '0',
    }

    if (inputParams.versionId) {
      params.version_id = inputParams.versionId
    }

    if (inputParams.publicationId) {
      params.publication_id = inputParams.publicationId
    }

    const channelId = isEmbedIdentifier(id) ? id.embedId : `${id.projectId}/${id.fileId}`

    super(backend, `/api/mipasa/socket`, `notebook:${channelId}`, params)
  }

  async fetchNotebook(): Promise<BackendNotebookConnectionNotebookPayload> {
    return this.sendSynchronousCommand('get_notebook')
  }

  async fetchCellOutput(
    cell_id: string,
    output_index: number,
    mime_type?: string,
    opts?: BackendNotebookConnectionCellOutputOpts,
  ): Promise<BackendNotebookConnectionCellOutputPayload> {
    return this.sendSynchronousCommand('get_cell_output', { cell_id, output_index, mime_type, page: opts?.page, page_size: opts?.pageSize })
  }

  async sendStartEditing(): Promise<BackendNotebookConnectionNotebookPayload> {
    return this.sendSynchronousCommand('start_editing')
  }

  async sendStartEditingCell(cell_id?: string, tag?: string, takeover?: boolean) {
    this.sendCommand('start_editing_cell', { cell_id: cell_id || null, tag: tag || null, takeover: takeover || null })
  }

  // lame client-side scheduling, because snippets are subjected to the same limitations as cells, but cannot be scheduled (unlike cells)
  private async fetchSnippetWithTimeout<P, R>(command: string, payload: P, timeout = 5000, retryFrequency = 250): Promise<R | null> {
    const startTime = new Date().getTime()

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const result: R | { error: string } = await this.sendSynchronousCommand(command, payload)

      // check if it's an error. even so, a specific error, for now.
      if ((result as { error: string }).error === 'busy_executor') {
        if (new Date().getTime() - startTime > timeout) {
          return null
        }

        await sleep(retryFrequency || 1)
        continue
      }

      // if it's not an error... well, not entirely correct, but let's just behave the old way.
      // the old way does not assume there can be an error at all.
      return result as R
    }
  }

  async fetchExecuteCodeSnippet(code: string): Promise<BackendNotebookConnectionExecuteCodeSnippetPayload | null> {
    return this.fetchSnippetWithTimeout('snippet:execute_code', { code })
  }

  async fetchVarsList(): Promise<BackendNotebookConnectionVarsListPayload | null> {
    return this.fetchSnippetWithTimeout('snippet:vars_list', {})
  }

  async fetchDataframeColumns(dataframeVar: string): Promise<BackendNotebookConnectionDataframeColumnsPayload | null> {
    return this.fetchSnippetWithTimeout('snippet:dataframe_columns', { dataframe_var: dataframeVar })
  }

  async fetchExcelSheets(filename: string): Promise<BackendNotebookConnectionExcelSheetsPayload | null> {
    return this.fetchSnippetWithTimeout('snippet:excel_sheets', { excel_file: filename })
  }

  async fetchCompletions(code: string, cursorPos: number): Promise<BackendNotebookConnectionCompletionsPayload> {
    return this.sendSynchronousCommand('get_completions', { code, cursor_pos: cursorPos })
  }

  sendClean(): void {
    this.sendCommand('clean')
  }

  sendInterrupt(): void {
    this.sendCommand('interrupt')
  }

  sendCollapseAllCells(collapsed: boolean): void {
    this.sendCommand('toggle_all_cells_collapsed', { collapsed })
  }

  sendCollapseCell(cell_id: string, collapsed: boolean): void {
    this.sendCommand('toggle_cell_collapsed', { cell_id, collapsed })
  }

  sendDeleteCell(cell_id: string): void {
    this.sendCommand('delete_cell', { cell_id })
  }

  sendExecuteAllCells(clean: boolean): void {
    this.sendCommand('execute_all_cells', { clean })
  }

  sendExecuteCells(cell_ids: string[], clean: boolean, with_deps = true): void {
    this.sendCommand('execute_cells', { cell_ids, clean, with_deps })
  }

  sendExecuteCell(cell_id: string, clean: boolean): void {
    this.sendCommand('execute_cell', { cell_id, clean })
  }

  sendInsertCell(cell_id: string, index: number, type: BackendNotebookConnectionCellType): void {
    this.sendCommand('insert_cell', { cell_id, index, type })
  }

  sendMoveCell(cell_id: string, index: number): void {
    this.sendCommand('move_cell', { cell_id, index })
  }

  sendSelectEnvironment(environment: string | null): void {
    this.sendCommand('select_environment', { environment })
  }

  sendToggleGPU(gpu: boolean): void {
    this.sendCommand('toggle_gpu', { gpu })
  }

  sendToggleAutorunAfterFilesUpdated(autorunAfterFilesUpdated: boolean): void {
    this.sendCommand('toggle_autorun_after_files_updated', { autorun_after_files_updated: autorunAfterFilesUpdated })
  }

  sendUpdateMetadata(metadata: Record<string, any>, shouldReplace = true): void {
    this.sendCommand('update_metadata', { metadata, 'replace?': shouldReplace })
  }

  sendSelectTheme(theme: string): void {
    this.sendCommand('select_theme', { theme })
  }

  sendSetCellType(cell_id: string, type: BackendNotebookConnectionCellType): void {
    this.sendCommand('set_cell_type', { cell_id, type })
  }

  sendUpdateCellMetadata(cell_id: string, metadata: NotebookCellMetadata): void {
    this.sendCommand('update_cell_metadata', { cell_id, metadata })
  }

  sendUpdateCellSource(cell_id: string, source: string): void {
    this.sendCommand('update_cell_source', { cell_id, source })
  }

  sendSaveVersion(): void {
    this.sendCommand('save_version', {})
  }

  sendRestoreVersion(version_id: string): void {
    this.sendCommand('restore_version', { version_id })
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    super.connect()
  }
}
