import { triggerToast, useConstCallback } from '@unbounded/unbounded-components'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { ExchangeMeta } from '~api/exchange/types'
import { useBackend, useExchangeBackend } from '~routes/platform'
import { isRequestAborted } from '~utils/authorized-fetch'
import { formatToCurrencyNumber } from '~utils/currency'
import { captureError } from '~utils/monitoring'
import useSignal from '~utils/signal'
import { ExchangeType } from './exchanges.types'
import { getPrecision } from './exchanges.utils'

const INITIAL_CONNECTED_EXCHANGES: ConnectedExchanges = {
  [ExchangeType.demo]: true,
}

type ConnectedExchanges = Partial<Record<ExchangeType, boolean>>

interface IUseConnectedExchanges {
  isLoading: boolean
  isConnected: Partial<Record<ExchangeType, boolean>>
  reloadConnections: () => Promise<void>
}

export function useConnectedExchanges(): IUseConnectedExchanges {
  const backend = useBackend()
  const [isLoading, setIsLoading] = useState(true)
  const [isConnected, setIsConnected] = useState<ConnectedExchanges>(INITIAL_CONNECTED_EXCHANGES)
  const abortControllerRef = useRef<AbortController>()

  const reloadConnections = useConstCallback(async () => {
    abortControllerRef.current?.abort()
    abortControllerRef.current = new AbortController()
    setIsLoading(true)

    try {
      const binanceConn = await backend.withSignal(abortControllerRef.current.signal).fetchBinanceConnection()

      setIsConnected(prev => ({ ...prev, [ExchangeType.binance]: binanceConn.isConnected }))
    } catch (e: any) {
      setIsConnected(INITIAL_CONNECTED_EXCHANGES)

      if (isRequestAborted(e)) return

      captureError(e)
      triggerToast('error', `Couldn't load exchange connections: ${e.message || 'Unknown error'}`)
    } finally {
      setIsLoading(false)
    }
  })

  useEffect(() => {
    reloadConnections()

    return () => {
      abortControllerRef.current?.abort()
    }
  }, [])

  return {
    isLoading,
    isConnected,
    reloadConnections,
  }
}

export function useExchangeMarkets(exchangeType?: ExchangeType) {
  const exchangeBackend = useExchangeBackend()
  return useSignal(exchangeBackend.getExchangeMarkets(exchangeType))
}

export function useExchangesMarkets(exchangeTypes: ExchangeType[]) {
  const exchangeBackend = useExchangeBackend()
  const exchangeTypesKeys = exchangeTypes.sort().join(':')
  const previousKeys = useRef(exchangeTypesKeys)

  const signal = useMemo(() => exchangeBackend.getExchangesMarkets(exchangeTypes), [exchangeTypesKeys])
  const markets = useSignal(signal)

  // Special case when `exchangeTypes` is dynamically loaded (e.g. in My portfolio page)
  // We need to manually trigger the signal to update the state
  useEffect(() => {
    if (previousKeys.current !== exchangeTypesKeys) {
      signal.trigger()
      previousKeys.current = exchangeTypesKeys
    }
  }, [exchangeTypesKeys])

  return markets
}

export function useFormatExchangeAmount(exchangeTypesOrExchangeType: ExchangeType[] | ExchangeType | undefined = []) {
  const exchangesMarkets = useExchangesMarkets(
    Array.isArray(exchangeTypesOrExchangeType) ? exchangeTypesOrExchangeType : [exchangeTypesOrExchangeType],
  )

  const formatExchangeAmount = useCallback(
    (currencyOrMarketSymbol: string, amount: number) => {
      const precision = getPrecision(Object.values(exchangesMarkets).filter(Boolean), currencyOrMarketSymbol)
      const maximumFractionDigits = Math.max(0, Math.round(-Math.log10(precision)))

      // May seem like it's better to use exchangeInstance.amountToPrecision(marketSymbol, amount) and only fallback to Intl
      // but it's raising crazy errors akin to lot size filters and it's doing floor instead of round on the values.
      return formatToCurrencyNumber(amount, { maximumFractionDigits })
    },
    [exchangesMarkets, exchangeTypesOrExchangeType],
  )

  const formatExchangeAmountWithSymbol = useConstCallback(
    (currencyOrMarketSymbol: string, amount: number) =>
      `${formatExchangeAmount(currencyOrMarketSymbol, amount)} ${currencyOrMarketSymbol.toUpperCase()}`,
  )

  return useMemo(() => ({ formatExchangeAmount, formatExchangeAmountWithSymbol }), [formatExchangeAmount, formatExchangeAmountWithSymbol])
}

export function useExchangeMeta(exchangeType?: ExchangeType) {
  const exchangeBackend = useExchangeBackend()
  return useSignal(exchangeBackend.getExchangeMeta(exchangeType))
}

export function useExchangesMeta<T extends ExchangeType[]>(exchangeTypes: T) {
  const exchangeBackend = useExchangeBackend()
  const exchangeTypesKeys = exchangeTypes.sort().join(':')
  const previousKeys = useRef(exchangeTypesKeys)

  const signal = useMemo(() => exchangeBackend.getExchangesMeta(exchangeTypes), [exchangeTypesKeys])
  const exchangesMeta = useSignal(signal) as Record<T[number], ExchangeMeta>

  // Special case when `exchangeTypes` is dynamically loaded (e.g. in My portfolio page)
  // We need to manually trigger the signal to update the state
  useEffect(() => {
    if (previousKeys.current !== exchangeTypesKeys) {
      signal.trigger()
      previousKeys.current = exchangeTypesKeys
    }
  }, [exchangeTypesKeys])

  return exchangesMeta
}
