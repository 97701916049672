import { ListingName, SliceState, TeamViewMode, ViewMode, ViewType } from './listing.types'

export const listingVersion = 6

export const initialState: SliceState = {
  [ListingName.projects]: {
    pageSize: 12,
    sortBy: 'updated_at',
    sortDirection: 'desc',
    viewType: ViewType.cards,
    mode: ViewMode.public,
  },
  [ListingName.notebookPublications]: {
    pageSize: 6,
    sortBy: 'published_at',
    sortDirection: 'desc',
    viewType: ViewType.cards,
    mode: ViewMode.public,
  },
  [ListingName.pasaPublications]: {
    pageSize: 6,
    sortBy: 'published_at',
    sortDirection: 'desc',
    viewType: ViewType.cards,
    mode: ViewMode.public,
  },
  [ListingName.canvasPublications]: {
    pageSize: 6,
    sortBy: 'published_at',
    sortDirection: 'desc',
    viewType: ViewType.cards,
    mode: ViewMode.public,
  },
  [ListingName.islePublications]: {
    pageSize: 6,
    sortBy: 'published_at',
    sortDirection: 'desc',
    viewType: ViewType.cards,
    mode: ViewMode.public,
  },
  [ListingName.strategyPublications]: {
    pageSize: 12,
    sortBy: 'performance',
    sortDirection: 'desc',
    viewType: ViewType.cards,
    mode: ViewMode.public,
  },
  [ListingName.portfolios]: {
    pageSize: 11,
    sortBy: 'portfolio_total_deposit',
    sortDirection: 'desc',
    viewType: ViewType.cards,
    mode: ViewMode.private,
  },
  [ListingName.teams]: {
    pageSize: 24,
    sortBy: 'created_at',
    sortDirection: 'desc',
    viewType: ViewType.cards,
    mode: TeamViewMode.my,
  },
}
