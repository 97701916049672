import { toast } from '@unbounded/unbounded-components'
import createGitHubNotification from '~routes/auth/github-notification'
import { authorizedJsonFetch } from '~utils/authorized-fetch'
import { clearCookie } from '~utils/cookie'
import type { UserState } from './auth.types'

export const dropHubspot = () => {
  clearCookie('__hstc', { path: '/' })
  clearCookie('hubspotutk', { path: '/' })
  clearCookie('__hssc', { path: '/' })
  clearCookie('__hssrc', { path: '/' })
  // make a new cookie...
  if (typeof window !== 'undefined') {
    const { pathname, search } = window.location
    // we are referencing half-unsafe JS here
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle,no-multi-assign
    const _hsq = (window._hsq = window._hsq || [])

    _hsq.push(['setPath', pathname + search])
    _hsq.push(['trackPageView'])
  }
}

export const checkGitHubUserOutdated = (user: UserState) => {
  if (!user.id) {
    return
  }
  const lastNotified = Number.parseInt(window.sessionStorage.lastGitHubNotified || '0', 10)
  const notifyDelay = 60 * 60 * 1000 // every hour

  if (new Date().getTime() - lastNotified < notifyDelay) {
    return
  }
  window.sessionStorage.lastGitHubNotified = new Date().getTime()
  authorizedJsonFetch(`/api/gitHubExpiration`).then(({ tokenUsed, expiresAt }: { tokenUsed: boolean; expiresAt: number | null }) => {
    // create notification if needed
    if (tokenUsed && expiresAt !== null) {
      const oneDay = 24 * 60 * 60 * 1000
      const daysLeft = Math.ceil((expiresAt - new Date().getTime()) / oneDay)

      if (daysLeft <= 10) {
        const text =
          daysLeft > 0
            ? `Your GitHub token is about to expire in ${daysLeft} day(s).`
            : `Your GitHub token has expired; GitHub integration will not work.`

        toast.warning(createGitHubNotification(text))
      }
    }
  })
}

export const userToStorageToken = (user?: UserState) => {
  if (user?.isAuthorized) {
    return user.id
  }

  return '<unauthorized>'
}
