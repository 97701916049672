type AdditionalProps = { [key: string]: string } | undefined

const encodeCookieValue = (v: string | undefined) => v

const propsToString = (additionalProps: AdditionalProps) => {
  let additionalPropsStr = ''

  if (additionalProps) {
    Object.getOwnPropertyNames(additionalProps).forEach(k => {
      additionalPropsStr += `; ${encodeCookieValue(k)}=${encodeCookieValue(additionalProps[k])}`
    })
  }
  return additionalPropsStr
}

export function setCookie(key: string, value: string | undefined | null, additionalProps: AdditionalProps = undefined) {
  // console.log('setCookie', key, value, additionalProps)
  if (!value) value = undefined
  if (typeof window === 'undefined') {
    return
  }
  const additionalPropsStr = propsToString(additionalProps)
  // console.log('setCookie', `${encodeCookieValue(key)}=${encodeCookieValue(value)}${additionalPropsStr}`)

  document.cookie = `${encodeCookieValue(key)}=${encodeCookieValue(value)}${additionalPropsStr}`
}

export function clearCookie(key: string, additionalProps: AdditionalProps) {
  // console.log('clearCookie', key, additionalProps)
  if (typeof window === 'undefined') {
    return
  }
  const additionalPropsStr = propsToString(additionalProps)

  document.cookie = `${encodeCookieValue(key)}=${additionalPropsStr}; expires=Thu, 01 Jan 1970 00:00:00 UTC`
}

export function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length !== 2) {
    return undefined
  }

  const cookie = parts.pop()

  return cookie?.split(';').shift()
}
