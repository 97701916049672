import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { GitHubConnection } from '~api'
import { fetchBalance } from './auth.balance'
import type { UserState } from './auth.types'
import { createDefaultUser } from './auth.utils'

interface SliceState {
  user: UserState
}

const initialState: SliceState = {
  user: createDefaultUser(),
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signin: (_state, action: PayloadAction<UserState>) => ({
      user: action.payload,
    }),
    signout: () => initialState,
    connect: (state, action: PayloadAction<GitHubConnection>) => {
      state.user.gitHub = action.payload.isConnected
        ? {
            username: action.payload.userName || '',
            avatar: action.payload.avatar || '',
          }
        : undefined
      return state
    },
    disconnect: state => {
      state.user.gitHub = undefined
      return state
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchBalance.pending, () => {
        // todo need implement
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.user.balance = action.payload.balance as number
      })
      .addCase(fetchBalance.rejected, state => {
        state.user.balance = undefined
      })
  },
})

export const authSlice = slice
export type AuthState = SliceState

const authReducer = slice.reducer

export default authReducer
